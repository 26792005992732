function PcLoader(loaderContainerEl, person, personId) {
  var status = PcChatTypes.LOADER_STATUS.DONE;


  this.setStatus = function(loaderStatus) {
    status = loaderStatus;
  };

  this.onCheck = function() {
    if (status == PcChatTypes.LOADER_STATUS.LOADING) {
      loaderContainerEl.show();
    } else {
      loaderContainerEl.hide();
    }
  };

  return {
    loaderContainerEl, person, personId,
    setStatus: this.setStatus,
    onCheck: this.onCheck
  }
}