function PcChatTypes() {}

PcChatTypes.PERSON = {
  "PERFORMER": "performer",
  "CUSTOMER": "customer"
};

PcChatTypes.FILE_ACCESS = {
  "ALL": "ALL",
  "OWNER": "OWNER"
};

PcChatTypes.PRIVATE_MESSAGE_TYPE = {
  "TEXT": "text",
  "FILE": "file"
};


PcChatTypes.LOADER_STATUS = {
  "DONE": "done",
  "LOADING": "loading"
};